import {
  faInr,
  faLocationDot,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import propertyService from "../services/propertyService";
import bookingService from "../services/bookingService";
import { handleErrors } from "./../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import personalInfoServices from "../services/profile-personal-info";
import { validateUserProfile } from "../../src/utils/validation/validate-booking";
import { Helmet } from "react-helmet-async";

const Booking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [flatPricing, setFlatPricing] = useState([]);
  const [flatDetails, setFlatDetails] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({}); // To hold validation errors

  const { formData, id, flatUrl } = location.state || {};

  const [userProfile, setUserProfile] = useState({
    fullName: "",
    emailAddress: "",
    phoneNo: "",
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  // Fetch user profile information on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await personalInfoServices.getProfileInfo();
        const { firstName, lastName, emailAddress, phoneNo } =
          response.data.result;
        const formattedPhoneNo =
          phoneNo && !phoneNo.startsWith("+") ? `+91${phoneNo}` : phoneNo;

        setUserProfile({
          fullName: `${firstName} ${lastName}`,
          emailAddress,
          phoneNo: formattedPhoneNo,
        });
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      }
    };
    fetchUserProfile();
  }, []);

  const calculateDepartureTime = (movingDate, duration) => {
    const date = new Date(movingDate);
    date.setMonth(date.getMonth() + parseInt(duration, 10));
    return formatDate(date);
  };

  useEffect(() => {
    if (!id || !flatUrl) return;
    const payload = {
      flatId: id,
      flatUrl: flatUrl,
    };
    const getPropertyDetail = async () => {
      setLoading(true);
      try {
        const response = await propertyService.getPropertyDetail(payload);
        setFlatPricing(response.data.result?._FlatPricing);
        setFlatDetails(response.data.result?._Flat);
        setPropertyDetails(response.data.result?._Property);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };
    getPropertyDetail();
  }, [id, flatUrl]);

  const duration = Number(formData?.sayDuration);

  const monthlyRent =
    duration <= 6 ? flatPricing.monthlyRentST : flatPricing.monthlyRentLT;
  const securityDeposit =
    duration <= 6
      ? flatPricing.monthlyRentSTDeposit
      : flatPricing.monthlyRentLTDeposit;
  const onboardingCharges = 2000;

  const moveInDate = new Date(formData.reqFrom);
  const monthEndDate = new Date(
    moveInDate.getFullYear(),
    moveInDate.getMonth() + 1,
    0
  );
  const daysLeftInMonth =
    (monthEndDate - moveInDate) / (1000 * 60 * 60 * 24) + 1;

  const currentMonthRent = Math.round((monthlyRent / 30) * daysLeftInMonth);
  const dailyWaterCharge = flatPricing.fixedWaterBill / 30;
  const proratedWaterCharges = Math.round(dailyWaterCharge * daysLeftInMonth);
  const totalAmountToPay =
    currentMonthRent +
    securityDeposit +
    onboardingCharges +
    flatPricing.flatMaintenance +
    proratedWaterCharges;
  const remainingAmount = totalAmountToPay - 12000;

  const reqFromDate = new Date(formData.reqFrom);
  const endDate = new Date(
    reqFromDate.getFullYear(),
    reqFromDate.getMonth() + 1,
    0
  );

  // Format endDate for display
  const formattedEndDate = formatDate(endDate);

  const handleProceedToPay = async () => {
    const errors = validateUserProfile(userProfile);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    const payload = {
      userName: userProfile.fullName,
      emailAddress: userProfile.emailAddress,
      contactNo: userProfile.phoneNo,
      moveInDate: new Date(formData.reqFrom).toISOString(),
      noOfMonths: parseInt(formData.sayDuration, 10),
      noOfGuest: parseInt(formData.guests, 10),
      flatId: id,
      tokenAmount: "Yes",
      onboardingCharge: "Yes",
    };

    try {
      const response = await bookingService.getBookingToken(payload);
      navigate("/checkout", {
        state: { orderId: response.data.result.order_id },
      });
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response && error.response.status === 500) {
        errorMessage =
          error.response.data.message ||
          "An unexpected error occurred. Please try again later.";
      }
      setError(errorMessage);
    }
  };

  return (
    <>
      <Helmet>
        <title>Booking | Sowerent</title>
        <meta
          name="description"
          content="Book your next rental property with Sowerent."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section className="section-padding booking-banner-text residence-banner">
        {error ? (
          <>
            <ErrorHandlingDesign errorMessage={error} />
            <div className="dashboard-link text-center">
              <Link to={"/"}>
                <Button>Go to Home Page</Button>
              </Link>
            </div>
          </>
        ) : (
          <Container className="booking-container">
            <Row>
              <Col>
                <div className="residence-title-wrap">
                  <div className="left-col">
                    <div className="title-wrap">
                      <h1>{flatDetails.flatName}</h1>
                      <div className="location">
                        <p>
                          <FontAwesomeIcon icon={faLocationDot} />
                          {propertyDetails.propertyLocation},{" "}
                          {propertyDetails.city}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="booking-meta-details">
                  <div className="booking-details-wrap">
                    <div className="booking-details-item">
                      <div className="title">Moving Date</div>
                      <div className="sub=title">
                        {formatDate(formData.reqFrom)}
                      </div>
                    </div>
                    <div className="booking-details-item">
                      <div className="title">Departure Time</div>
                      <div className="sub=title">
                        {calculateDepartureTime(
                          formData.reqFrom,
                          formData.sayDuration
                        )}
                      </div>
                    </div>
                    <div className="booking-details-item">
                      <div className="title">Duration</div>
                      <div className="sub=title">
                        {formData.sayDuration}{" "}
                        {formData.sayDuration === "1" ? "Month" : "Months"}
                      </div>
                    </div>
                    <div className="booking-details-item">
                      <div className="title">Guests</div>
                      <div className="sub=title">{formData.guests}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap gap-3">
                    <div className="token-amount">
                      <span className="token-text">Token Amount</span>
                      <span className="amount">
                        <FontAwesomeIcon icon={faInr} /> 12000
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="rental-info">
                  <div className="left-col">
                    <div className="userform-details">
                      <Form>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="fullName">Full Name</Form.Label>
                          <Form.Control
                            id="user-name"
                            type="text"
                            value={userProfile.fullName}
                            onChange={(e) =>
                              setUserProfile({
                                ...userProfile,
                                fullName: e.target.value,
                              })
                            }
                            {...(validationErrors.fullName
                              ? { isInvalid: true }
                              : {})}
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationErrors.fullName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="emailAddress">
                            Email Address
                          </Form.Label>
                          <Form.Control
                            id="user-email"
                            type="email"
                            value={userProfile.emailAddress}
                            onChange={(e) =>
                              setUserProfile({
                                ...userProfile,
                                emailAddress: e.target.value,
                              })
                            }
                            {...(validationErrors.emailAddress
                              ? { isInvalid: true }
                              : {})} // Show error state
                          />
                          <Form.Control.Feedback type="invalid">
                            {validationErrors.emailAddress}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="phoneNo">
                            Mobile Number
                          </Form.Label>
                          <PhoneInput
                            id="user-no"
                            international
                            maxLength="15"
                            defaultCountry="IN" // Defaults to Indian format
                            value={userProfile.phoneNo}
                            onChange={(value) => {
                              setUserProfile({
                                ...userProfile,
                                phoneNo: value,
                              });
                            }}
                          />
                          {validationErrors.phoneNo && (
                            <div className="invalid-feedback d-block">
                              {validationErrors.phoneNo}
                            </div>
                          )}
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                  <div className="right-col">
                    <div className="all-payment-amount-details">
                      <div className="item">
                        <div className="content-wrap">
                          <div className="title">
                            <h3 className="d-flex flex-column">
                              Current Month Rent
                              <sub>
                                ({formatDate(formData.reqFrom)} -{" "}
                                {formattedEndDate})
                              </sub>
                            </h3>
                          </div>
                          <div className="amount-charges">
                            <span>
                              <FontAwesomeIcon icon={faInr} />
                            </span>
                            {currentMonthRent}
                          </div>
                        </div>
                      </div>

                      <div className="item">
                        <div className="content-wrap">
                          <div className="title">
                            <h3>Security Deposit</h3>
                            <p>Pay online to sowerent, refunded at move-out</p>
                          </div>
                          <div className="amount-charges">
                            <span>
                              <FontAwesomeIcon icon={faInr} />
                            </span>
                            {securityDeposit}
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="content-wrap">
                          <div className="title">
                            <h3>Water Charges</h3>
                          </div>
                          <div className="amount-charges">
                            <span>
                              <FontAwesomeIcon icon={faInr} />
                            </span>
                            {proratedWaterCharges}
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="content-wrap">
                          <div className="title">
                            <h3>Maintenance Charges</h3>
                          </div>
                          <div className="amount-charges">
                            <span>
                              <FontAwesomeIcon icon={faInr} />
                            </span>
                            {flatPricing.flatMaintenance}
                          </div>
                        </div>
                      </div>

                      <div className="item">
                        <div className="content-wrap">
                          <div className="title">
                            <h3 className="d-flex flex-column">
                              Onboarding Charges <sub>(One time only)</sub>
                            </h3>
                            <p>One time payable</p>
                          </div>
                          <div className="amount-charges">
                            <span>
                              <FontAwesomeIcon icon={faInr} />
                            </span>
                            {onboardingCharges}
                          </div>
                        </div>
                      </div>
                      <div className="payable-amount item">
                        <div className="content-wrap">
                          <div className="title">
                            <h3>Total Amount to Pay</h3>
                            <p>One time payable</p>
                          </div>
                          <div className="amount-charges">
                            <span>
                              <FontAwesomeIcon icon={faInr} />
                            </span>
                            {totalAmountToPay}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="charges-to-pay-now mb-2">
                      <div className="title-wrap">
                        <h5>Charges to pay now</h5>
                        <div className="amount-to-pay-now">
                          <FontAwesomeIcon icon={faInr} />
                          12000
                        </div>
                      </div>
                      <div className="wrap">
                        <span>Note</span>
                        <p>
                          Please Pay Remaining amount of{" "}
                          <b>
                            {" "}
                            <FontAwesomeIcon icon={faInr} /> {remainingAmount}
                          </b>{" "}
                          on or before <b>{formatDate(formData.reqFrom)}</b>,
                          12:00 AM .
                        </p>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        className="proceed-button"
                        onClick={handleProceedToPay}
                      >
                        Proceed to Pay
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </>
  );
};

export default Booking;
