import React, { useLayoutEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Footer from "./components/common/footer";
import Navbar from "./components/common/header";
import routes from "./routes/router.config";
import { UserProvider } from "../src/components/context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }, [pathname]);

    return null;
  };

  return (
    <main>
      <TawkMessengerReact
        propertyId="66925cd432dca6db2caecf73"
        widgetId="1i2lrl7jf"
      />
      <UserProvider>
        <HelmetProvider>
          <Router>
            <ToastContainer autoClose={2000} />
            <ScrollToTop />
            <Navbar />
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element}>
                  {route.children?.map((child, idx) => (
                    <Route
                      key={idx}
                      path={child.path}
                      element={child.element}
                    />
                  ))}
                </Route>
              ))}
            </Routes>
            <Footer />
          </Router>
        </HelmetProvider>
      </UserProvider>
    </main>
  );
}

export default App;
