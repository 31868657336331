import {
  faChevronDown,
  faHeart,
  faInr,
  faLocationDot,
  faPhone,
  faShareAlt,
  faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
import { Calendar } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressSpinner } from "primereact/progressspinner";
// import TermsConditions from "./terms-conditions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import bedIcon from "../assets/images/icons/bed-icon.svg";
import bathIcon from "../assets/images/icons/bath-icon.svg";
import BuildingIcon from "../assets/images/icons/building-icon.svg";
import ParkingIcon from "../assets/images/icons/garage-icon.svg";
import HallIcon from "../assets/images/icons/couch.png";
import comBuildingIcon from "../assets/images/icons/commercial-building.png";
import residentialIcon from "../assets/images/icons/residential.png";
import restaurantIcon from "../assets/images/icons/restaurant.png";
import hospitalBuildingIcon from "../assets/images/icons/hospital-building.png";
import mandirTempleIcon from "../assets/images/icons/mandir-temple.png";
import shoppingMallIcon from "../assets/images/icons/shopping-mall.png";
import { validateBookingForm } from "../utils/validation/validate-booking-form";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-video.css";
import lgVdeo from "lightgallery/plugins/video";
import ScheduleMeeting from "../components/common/schedule-meeting";
import propertyService from "../services/propertyService";
import videoThumbnail from "../assets/images/video-thubnail.jpg";
import { handleErrors } from "../utils/errorHandler";
import { useNavigate } from "react-router-dom";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import LoginForm from "../components/common/LoginForm";

import { useUser } from "../components/context/UserContext"; // Adjust the import path
import { Helmet } from "react-helmet-async";
import TermsCondition from "../components/common/terms-condition";

const exploreNearBy = {
  BusinessCentres: "Business Centres",
  Temples: "Temples",
  Eatouts: "Eatouts",
  Shopping: "Shopping",
  Hospitals: "Hospitals",
  NearestProperties: "Nearest Properties",
};

const PropertyDetail = ({ propertyId }) => {
  const lightGallery = useRef(null);
  const [isReadMore, setIsReadMore] = useState(false);
  const [id, setId] = useState();
  const [slug, setSlug] = useState();
  const [propData, setPropData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    reqFrom: "",
    sayDuration: "",
    guests: "",
    terms: false,
  });
  const [errors, setErrors] = useState({});
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [propertyNearBy, setPropertyNearBy] = useState([]);
  const [propertyAmenities, setPropertyAmenities] = useState([]);
  const [bannerGallery, setBannerGallery] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const { user, addToWishlist, removeFromWishlist } = useUser();
  const flatId = propData?._Flat?.id; // Assuming flatId is available in propData
  const [showModal, setShowModal] = useState(false); // Modal visibility state

  const handleShow = () => setShowModal(true); // Show modal
  const handleClose = () => setShowModal(false); // Close modal

  const preferredTenants = propData?._Flat?.preferredTenants.replace(/\s/g, "");

  const location = useParams();
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const onOpen = (index) => {
    lightGallery.current.openGallery(index);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Update on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const pathname = location.slug;
    const lastSegment = pathname.split("-");
    const extractId = lastSegment.pop();
    const extractUrl = lastSegment.join("-");
    setId(extractId);
    setSlug(extractUrl);
  }, [location]);

  useEffect(() => {
    if (flatId) {
      setIsWishlisted(user?.WishList?.some((item) => item.flatId === flatId));
    }
  }, [user.WishList, flatId]);

  useEffect(() => {
    if (!id || !slug) return;
    const payload = {
      flatId: id,
      flatUrl: slug,
    };
    const getPropertyDetail = async () => {
      setLoading(true);
      try {
        const response = await propertyService.getPropertyDetail(payload);
        setPropData(response.data.result);
        setPropertyNearBy(response.data.result?._PropertyNearBy);
        setBannerGallery(response.data.result?._FlatGallery);
        setPropertyAmenities(response.data.result?._FlatAmenities);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };
    getPropertyDetail();
  }, [id, slug]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const isAuthenticated = () => {
    const accessToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("accessToken="));
    return accessToken && tokenIsValid(accessToken.split("=")[1]);
  };

  const tokenIsValid = (token) => {
    return !!token;
  };

  const handleSubmit = () => {
    // e.preventDefault();

    const validationErrors = validateBookingForm(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (isAuthenticated()) {
        // Proceed to booking if authenticated
        navigate("/booking", { state: { formData, id: id, flatUrl: slug } });
      } else {
        // Trigger login popup if not authenticated
        setShowLoginModal(true);
      }
    }
  };

  // const handleLoginSuccess = () => {
  //   setShowLoginModal(false);
  // };

  const formatRent = (rent) => {
    return rent
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/^(\d+)(?=(\d{2})+(?!\d))/, "$&,");
  };

  const closeScheduleMeeting = () => setShowScheduleModal(false);
  const showScheduleMeeting = () => setShowScheduleModal(true);
  // const [activeSection, setActiveSection] = useState("");

  const propertyVideos = bannerGallery
    .map((item, index) => (item.fileType === "Video" ? { item, index } : null))
    .filter((entry) => entry !== null);
  const durationOptions = Array.from({ length: 11 }, (_, index) => index + 1);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionTop + 80,
        behavior: "smooth",
      });
    }
  };

  // booking functionality - sending the data to booking page

  const navigate = useNavigate();

  const handleDateChange = (e) => {
    setFormData({ ...formData, reqFrom: e.value });
  };

  let today = new Date();
  let maxDate = new Date();
  maxDate.setDate(today.getDate() + 10);

  let minDate = today;
  if (propData?._Flat?.availableFrom) {
    const availableFromDate = new Date(propData._Flat.availableFrom);
    if (availableFromDate > today) {
      minDate = availableFromDate;
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (date < today) {
      return "Immediately";
    }

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const totalGuests =
    propData?._Flat?.noOfPersonAllowed + propData?._Flat?.extraAllowed;
  // --------------------------------------------------------------

  if (error) {
    return <ErrorHandlingDesign errorMessage={error} />;
  }

  const handleWishlist = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    // Check if the user is authenticated before allowing wishlist operations
    if (!user.isAuthenticated) {
      toast.error("Please log in to add or remove items from your wishlist.");
      return; // Exit function if user is not authenticated
    }

    try {
      if (isWishlisted) {
        const wishListId = user?.WishList.find(
          (item) => item.flatId === flatId
        )?.id;
        await removeFromWishlist(wishListId);
        setIsWishlisted(false); // Update state to reflect removal
      } else {
        await addToWishlist(flatId);
        setIsWishlisted(true); // Update state to reflect addition
      }
    } catch (error) {
      console.error("Error during wishlist operation:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  // const shareOnWhatsApp = () => {
  //   const currentUrl = window.location.href;
  //   const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
  //     currentUrl
  //   )}`;
  //   window.open(whatsappUrl);
  // };

  // const shareOnWhats = () => {
  //   const propertyDetails = `
  //   http://maps.google.com/?q=${propData?._Property?.propertyLat},${propData?._Property?.propertyLong}
  //   `;
  //   const whatsappURL = `https://wa.me/?text=${encodeURIComponent(
  //     propertyDetails
  //   )}`;
  //   window.location.href(whatsappURL);
  // };

  return (
    <>
      <Helmet>
        <title>
          {propData?._Flat?.pageTitle
            ? propData?._Flat?.pageTitle
            : `${propData?._Flat?.bhkType} Apartment In ${propData?._Property?.propertyName} for Rent In
          ${propData?._Property?.propertyLocation}`}
        </title>
        <meta name="description" content={propData?._Flat?.metaDesc} />
        <meta name="keywords" content={propData?._Flat?.metaKeys} />
      </Helmet>

      {loading ? (
        <section className="loading">
          <div className="text-center">
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="3"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        </section>
      ) : (
        <>
          <section className="custom-breadcrumb bg-white">
            <Container>
              <Row>
                <Col sm={12}>
                  <ul className="bread-crumb-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/properties">{propData?._Property?.city}</Link>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to={`/rent/bangalore/${propData?._Property?.propertyLocation}`}
                      >
                        {propData?._Property?.propertyLocation}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{propData?._Flat?.flatName}</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="residence-banner">
            <Container>
              <Row>
                <Col>
                  <div className="residence-title-wrap">
                    <div className="left-col">
                      <div className="title-wrap">
                        <h1>{propData?._Flat?.flatName}</h1>
                        <div className="location">
                          <p>
                            <FontAwesomeIcon icon={faLocationDot} />
                            {propData?._Property?.propertyLocation},{" "}
                            {propData?._Property?.city}
                          </p>
                          <Link
                            to={`https://wa.me/?text=${encodeURIComponent(
                              `http://maps.google.com/?q=${propData?._Property?.propertyLat},${propData?._Property?.propertyLong}`
                            )}`}
                            target="_blank"
                            // onClick={() => shareOnWhats()}
                          >
                            <span>-</span>Get a Location on WhatsApp
                          </Link>
                        </div>
                        <div className="high-lights">
                          <ul>
                            <li>
                              Managed By {propData?._Flat?.propertyManagedBy}
                            </li>
                            <li>{propData?._Flat?.bhkType}</li>
                            <li>{propData?._Flat?.preferredTenants}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="right-col">
                      <div className="right-content">
                        <div className="sortlist-wrap">
                          <div className="wishlist" onClick={handleWishlist}>
                            <span className="cursor-pointer">
                              <FontAwesomeIcon
                                icon={faHeart}
                                style={{
                                  color:
                                    isWishlisted && user.isAuthenticated
                                      ? "red"
                                      : "gray",
                                }}
                              />
                            </span>
                          </div>
                          <Link
                            className="icon"
                            to={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                              window.location.href
                            )}`}
                            target="_blank"
                            // onClick={() => shareOnWhatsApp()}
                          >
                            <FontAwesomeIcon icon={faShareAlt} />
                          </Link>
                        </div>
                        <div className="contact-owner tab-show">
                          <Button
                            onClick={() => scrollToSection("book-now")}
                            className="btn btn-primary orange-btn"
                          >
                            Book Now
                          </Button>
                        </div>
                        <div className="contact-owner">
                          <Button
                            className="btn btn-primary"
                            onClick={showScheduleMeeting}
                          >
                            Schedule Visit
                          </Button>
                          <Modal
                            className="schedule-visit-modal"
                            show={showScheduleModal}
                            onHide={closeScheduleMeeting}
                          >
                            <Modal.Body>
                              <ScheduleMeeting
                                busy="String"
                                vitualVisit={propertyVideos}
                                showCancel={true}
                                onCancleBtnClick={closeScheduleMeeting}
                              />
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="position-relative">
                  {bannerGallery.length > 0 || propertyVideos.length > 0 ? (
                    isMobile ? (
                      <LightGallery
                        plugins={[lgZoom, lgVdeo]}
                        onInit={onInit}
                        dynamic={true}
                        mode="lg-fade"
                        pager={false}
                        thumbnail={true}
                        galleryId={"nature"}
                        autoplayFirstVideo={false}
                        elementClassNames={"gallery"}
                        dynamicEl={bannerGallery.map((item) => ({
                          src: item.fileLink,
                          thumb: item.fileLink,
                          subHtml: item.fileNm,
                        }))}
                        mobileSettings={{
                          controls: false,
                          download: false,
                          rotate: false,
                        }}
                      >
                        <Swiper
                          modules={[Pagination]}
                          pagination={{
                            clickable: true,
                          }}
                          spaceBetween={10}
                          slidesPerView={1}
                        >
                          {bannerGallery.map((item, index) => (
                            <SwiperSlide key={index}>
                              <div
                                className="gallery__item"
                                data-src={item.fileLink}
                                onClick={() => onOpen(index)}
                                data-index={index}
                                data-sub-html={item.fileNm}
                              >
                                <img src={item.fileLink} alt={item.fileNm} />
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </LightGallery>
                    ) : (
                      <>
                        <LightGallery
                          plugins={[lgZoom, lgVdeo]}
                          onInit={onInit}
                          dynamic={true}
                          mode="lg-fade"
                          pager={false}
                          thumbnail={true}
                          galleryId={"nature"}
                          autoplayFirstVideo={false}
                          elementClassNames={"gallery"}
                          dynamicEl={bannerGallery.map((item) => ({
                            src: item.fileLink,
                            thumb: item.fileLink,
                            subHtml: item.fileNm,
                          }))}
                          mobileSettings={{
                            controls: false,
                            download: false,
                            rotate: false,
                          }}
                        >
                          <div
                            key={`gallery__itemKey-${1}`}
                            className="gallery__item"
                            data-src={bannerGallery[0]?.fileLink}
                            onClick={() => onOpen(0)}
                            data-index={0}
                            data-sub-html={bannerGallery[0]?.fileNm}
                          >
                            <img src={bannerGallery[0]?.fileLink} alt="" />
                          </div>
                          {bannerGallery.length <= 4 ? (
                            bannerGallery
                              .filter((_, index) => index === 1)
                              .map((item, index = 1) => (
                                <div
                                  className="gallery__item banner-col-grid-layout"
                                  data-src={item?.fileLink}
                                  onClick={() => onOpen(index + 1)}
                                  data-index={index + 1}
                                  data-sub-html={item?.fileNm}
                                  key={`gallery__itemKey-${index}`}
                                >
                                  <img src={item?.fileLink} alt="" />
                                </div>
                              ))
                          ) : (
                            <>
                              <div
                                key={`gallery__itemKey-${2}`}
                                className="gallery__item"
                                data-src={bannerGallery[1]?.fileLink}
                                onClick={() => onOpen(1)}
                                data-index={1}
                                data-sub-html={bannerGallery[1]?.fileNm}
                              >
                                <img src={bannerGallery[1]?.fileLink} alt="" />
                              </div>
                              {propertyVideos.length > 0 ? (
                                <>
                                  <div
                                    key={`gallery__itemKey-${3}`}
                                    className="gallery__item video-type"
                                    data-src={propertyVideos[0]?.item?.fileLink}
                                    onClick={() =>
                                      onOpen(propertyVideos[0]?.index)
                                    }
                                    data-index={2}
                                    data-sub-html={
                                      propertyVideos[0]?.item?.fileNm
                                    }
                                  >
                                    <img src={videoThumbnail} alt="" />
                                  </div>
                                  {bannerGallery
                                    .filter(
                                      (item, index) => index >= 3 && index < 5
                                    )
                                    .map((item, index = 3) => (
                                      <div
                                        className="gallery__item"
                                        data-src={item?.fileLink}
                                        onClick={() => onOpen(index + 2)}
                                        data-index={index + 2}
                                        data-sub-html={item?.fileNm}
                                        key={`gallery__itemKey-${index}`}
                                      >
                                        <img src={item?.fileLink} alt="" />
                                      </div>
                                    ))}
                                </>
                              ) : (
                                bannerGallery
                                  .filter(
                                    (item, index) => index >= 2 && index < 5
                                  )
                                  .map((item, index = 2) => (
                                    <div
                                      className="gallery__item"
                                      data-src={item?.fileLink}
                                      onClick={() => onOpen(index + 2)}
                                      data-index={index + 2}
                                      data-sub-html={item?.fileNm}
                                      key={`gallery__itemKey-${index}`}
                                    >
                                      <img src={item?.fileLink} alt="" />
                                    </div>
                                  ))
                              )}
                            </>
                          )}
                        </LightGallery>
                        {bannerGallery.length >= 2 && (
                          <div className="show-all-image-btn">
                            <Button
                              className="btn-secondary"
                              onClick={() => {
                                const openImage =
                                  bannerGallery.length < 1 ? 4 : 2;
                                lightGallery.current.openGallery(openImage);
                              }}
                              id="gallery-btn"
                            >
                              <FontAwesomeIcon
                                className="me-1"
                                icon={faTableCellsLarge}
                              />{" "}
                              Show all Images
                            </Button>
                          </div>
                        )}
                      </>
                    )
                  ) : null}
                </Col>
              </Row>
            </Container>
          </section>

          <section className="residency-details-section">
            <Container>
              <div className="residency-details-wrap">
                <div className="left-col">
                  <div className="property-rental-wrap property-content-gap detail-card">
                    <div className="rent-wrap">
                      <div className="term">Long Term</div>
                      <div className="rent-month">
                        <span className="amount">
                          <FontAwesomeIcon icon={faInr} />
                          {propData?._FlatPricing?.monthlyRentLT}
                        </span>
                        /Month
                      </div>
                      <div className="deposit">
                        + Deposit{" "}
                        <span className="amount">
                          <FontAwesomeIcon icon={faInr} />
                          {propData?._FlatPricing?.monthlyRentLTDeposit}
                        </span>
                      </div>
                    </div>
                    <div className="rent-wrap">
                      <div className="term">Short Term</div>
                      <div className="rent-month">
                        <span className="amount">
                          <FontAwesomeIcon icon={faInr} />
                          {propData?._FlatPricing?.monthlyRentST}
                        </span>
                        /Month
                      </div>
                      <div className="deposit">
                        + Deposit{" "}
                        <span className="amount">
                          <FontAwesomeIcon icon={faInr} />
                          {propData?._FlatPricing?.monthlyRentSTDeposit}
                        </span>
                      </div>
                    </div>
                    <div className="rent-wrap">
                      <div className="term">Daily</div>
                      <div className="for-daily">
                        <Link to={"tel:+918867319933"}>
                          <FontAwesomeIcon icon={faPhone} className="me-2" />{" "}
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="property-highlights property-content-gap">
                    <div className="property-highlights-card detail-card">
                      <h2>Property Highlights</h2>
                      <ul className="property-overview  collapse-view">
                        <li>
                          <img src={bedIcon} alt="" />
                          <p>
                            {propData?._Flat?.noOfBedRooms}{" "}
                            {propData?._Flat?.noOfBedRooms === 1
                              ? "Bedroom"
                              : "Bedrooms"}
                          </p>
                        </li>
                        <li>
                          <img src={bathIcon} alt="" />
                          <p>
                            {propData?._Flat?.noOfBathRooms}{" "}
                            {propData?._Flat?.noOfBathRooms === 1
                              ? "Bathroom"
                              : "Bathrooms"}{" "}
                          </p>
                        </li>
                        <li>
                          <img src={BuildingIcon} alt="" />
                          <p>{propData?._Flat?.flatSize} Sqft</p>
                        </li>
                        <li>
                          <img src={HallIcon} alt="" />
                          <p>{propData?._Flat?.flatSize} Hall</p>
                        </li>
                        <li>
                          <img src={ParkingIcon} alt="" />
                          <p>{propData?._Flat?.parking} Parking</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="sticky-stripe">
                    <div className="detail-card py-0">
                      <ul className="sticky-list">
                        <li onClick={() => scrollToSection("about")}>About</li>
                        <li onClick={() => scrollToSection("amenities")}>
                          Amenities
                        </li>
                        <li onClick={() => scrollToSection("explore-nearby")}>
                          Explore Nearby
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="about-property" id="about">
                    <div className="detail-card">
                      <div className="about-inner-content mb-4">
                        <h3>
                          <span>Property Brief</span>
                        </h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: propData?._Flat?.flatDesc,
                          }}
                          className={`content-wrap ${
                            isReadMore ? "read-full-para" : ""
                          }`}
                        ></div>
                        <div
                          className="read-more-less"
                          onClick={() => setIsReadMore(!isReadMore)}
                        >
                          <span>
                            {!isReadMore ? "Read More" : "Read Less"}{" "}
                            <FontAwesomeIcon
                              className={`${isReadMore ? "rotate" : ""}`}
                              icon={faChevronDown}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="property-details-wrap">
                        <h3>
                          <span>Property Details</span>
                        </h3>
                        <ul className="property-details">
                          <li>
                            Property Name{" "}
                            <span>
                              {propData?._Property?.propertyName
                                ? propData?._Property?.propertyName
                                : "-"}
                            </span>
                          </li>
                          <li>
                            Property Type{" "}
                            <span>
                              {propData?._Property?.propertyType
                                ? propData?._Property?.propertyType
                                : "-"}
                            </span>
                          </li>
                          <li>
                            Total No of floors{" "}
                            <span>
                              {propData?._Property?.noOfFloors
                                ? propData?._Property?.noOfFloors
                                : "-"}
                            </span>
                          </li>
                          <li>
                            House Type{" "}
                            <span>
                              {propData?._Flat?.bhkType
                                ? propData?._Flat?.bhkType
                                : "-"}
                            </span>
                          </li>
                          <li>
                            Furnishing Type{" "}
                            <span>
                              {propData?._Flat?.furnished
                                ? propData?._Flat?.furnished
                                : "-"}
                            </span>
                          </li>
                          <li>
                            Flat No{" "}
                            <span>
                              {propData?._Flat?.flatNo
                                ? propData?._Flat?.flatNo
                                : "-"}
                            </span>
                          </li>

                          <li>
                            Main Door Facing{" "}
                            <span>
                              {propData?._Flat?.facing
                                ? propData?._Flat?.facing
                                : "-"}
                            </span>
                          </li>

                          <li>
                            Operating Since{" "}
                            <span>
                              {propData?._Property?.buldingAge
                                ? propData?._Property?.buldingAge
                                : "-"}{" "}
                              Years
                            </span>
                          </li>
                          <li>
                            Property Size{" "}
                            <span>
                              {propData?._Flat?.flatSize
                                ? propData?._Flat?.flatSize
                                : "-"}{" "}
                              sqft.
                            </span>
                          </li>
                          <li>
                            Balcony{" "}
                            <span>
                              {propData?._Flat?.noOfBalcony
                                ? propData?._Flat?.noOfBalcony
                                : "-"}
                            </span>
                          </li>
                          <li>
                            City{" "}
                            <span>
                              {propData?._Property?.city
                                ? propData?._Property?.city
                                : "-"}
                            </span>
                          </li>
                          <li>
                            Bedrooms{" "}
                            <span>
                              {propData?._Flat?.noOfBedRooms
                                ? propData?._Flat?.noOfBedRooms
                                : "-"}
                            </span>
                          </li>
                          <li>
                            Bathrooms{" "}
                            <span>
                              {propData?._Flat?.noOfBathRooms
                                ? propData?._Flat?.noOfBathRooms
                                : "-"}
                            </span>
                          </li>
                          <li>
                            No. of Guests{" "}
                            <span>
                              {propData?._Flat?.noOfPersonAllowed
                                ? propData?._Flat?.noOfPersonAllowed
                                : "-"}
                            </span>
                          </li>
                          <li>
                            No. of Extra Guests Allowed{" "}
                            <span>
                              {propData?._Flat?.extraAllowed
                                ? propData?._Flat?.extraAllowed
                                : "-"}
                            </span>
                          </li>
                          <li>
                            Pets{" "}
                            <span>
                              {propData?._Flat?.petsAllowed
                                ? "Allowed"
                                : "Not Allowed"}
                            </span>
                          </li>
                          <li>
                            Family{" "}
                            <span>
                              {preferredTenants?.split(",").includes("Family")
                                ? "Allowed"
                                : "Not Allowed"}
                            </span>
                          </li>
                          <li>
                            Bachelor{" "}
                            <span>
                              {preferredTenants?.split(",").includes("Bachelor")
                                ? "Allowed"
                                : "Not Allowed"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="amenities" id="amenities">
                    <div className="detail-card">
                      <div className="property-details-wrap">
                        <h2>Amenities</h2>
                        <ul className="property-amenities">
                          {propertyAmenities.map((item, index) => (
                            <li key={`amenities-index-${index}`}>
                              <img src={item.amenityIcon} alt="" />
                              <span>{item.amenityName}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {propertyNearBy.length > 0 && (
                    <div className="explore-nearby" id="explore-nearby">
                      <div className="detail-card">
                        <div className="property-details-wrap">
                          <h2>Explore Nearby</h2>
                          <div className="nearby-areas-properties">
                            {/* Business Centres */}
                            {propertyNearBy.filter(
                              (nearBy) =>
                                nearBy.nearByTypes ===
                                exploreNearBy.BusinessCentres
                            ).length > 0 && (
                              <div className="nearby-areas-properties-item">
                                <h3>
                                  <img src={comBuildingIcon} alt="" />
                                  Business Centres
                                </h3>
                                <ul className="nearby-areas-properties-list">
                                  {propertyNearBy
                                    .filter(
                                      (nearBy) =>
                                        nearBy.nearByTypes ===
                                        exploreNearBy.BusinessCentres
                                    )
                                    .map((item, index) => (
                                      <li key={`BusinessCentres-${index}`}>
                                        <span className="location-name">
                                          {item.nearByName}
                                        </span>{" "}
                                        -{" "}
                                        <span className="location-distance">
                                          {item.distanceInKM} KM
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}

                            {/* Shopping */}
                            {propertyNearBy.filter(
                              (nearBy) =>
                                nearBy.nearByTypes === exploreNearBy.Shopping
                            ).length > 0 && (
                              <div className="nearby-areas-properties-item">
                                <h3>
                                  <img src={shoppingMallIcon} alt="" />
                                  Shopping
                                </h3>
                                <ul className="nearby-areas-properties-list">
                                  {propertyNearBy
                                    .filter(
                                      (nearBy) =>
                                        nearBy.nearByTypes ===
                                        exploreNearBy.Shopping
                                    )
                                    .map((item, index) => (
                                      <li key={`Shopping-${index}`}>
                                        <span className="location-name">
                                          {item.nearByName}
                                        </span>{" "}
                                        -{" "}
                                        <span className="location-distance">
                                          {item.distanceInKM} KM
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}

                            {/* Temples */}
                            {propertyNearBy.filter(
                              (nearBy) =>
                                nearBy.nearByTypes === exploreNearBy.Temples
                            ).length > 0 && (
                              <div className="nearby-areas-properties-item">
                                <h3>
                                  <img src={mandirTempleIcon} alt="" />
                                  Temples
                                </h3>
                                <ul className="nearby-areas-properties-list">
                                  {propertyNearBy
                                    .filter(
                                      (nearBy) =>
                                        nearBy.nearByTypes ===
                                        exploreNearBy.Temples
                                    )
                                    .map((item, index) => (
                                      <li key={`Temples-${index}`}>
                                        <span className="location-name">
                                          {item.nearByName}
                                        </span>{" "}
                                        -{" "}
                                        <span className="location-distance">
                                          {item.distanceInKM} KM
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}

                            {/* Hospitals */}
                            {propertyNearBy.filter(
                              (nearBy) =>
                                nearBy.nearByTypes === exploreNearBy.Hospitals
                            ).length > 0 && (
                              <div className="nearby-areas-properties-item">
                                <h3>
                                  <img src={hospitalBuildingIcon} alt="" />
                                  Hospitals
                                </h3>
                                <ul className="nearby-areas-properties-list">
                                  {propertyNearBy
                                    .filter(
                                      (nearBy) =>
                                        nearBy.nearByTypes ===
                                        exploreNearBy.Hospitals
                                    )
                                    .map((item, index) => (
                                      <li key={`Hospitals-${index}`}>
                                        <span className="location-name">
                                          {item.nearByName}
                                        </span>{" "}
                                        -{" "}
                                        <span className="location-distance">
                                          {item.distanceInKM} KM
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}

                            {/* Eatouts */}
                            {propertyNearBy.filter(
                              (nearBy) =>
                                nearBy.nearByTypes === exploreNearBy.Eatouts
                            ).length > 0 && (
                              <div className="nearby-areas-properties-item">
                                <h3>
                                  <img src={restaurantIcon} alt="" />
                                  Eatouts
                                </h3>
                                <ul className="nearby-areas-properties-list">
                                  {propertyNearBy
                                    .filter(
                                      (nearBy) =>
                                        nearBy.nearByTypes ===
                                        exploreNearBy.Eatouts
                                    )
                                    .map((item, index) => (
                                      <li key={`Eatouts-${index}`}>
                                        <span className="location-name">
                                          {item.nearByName}
                                        </span>{" "}
                                        -{" "}
                                        <span className="location-distance">
                                          {item.distanceInKM} KM
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}

                            {/* Nearest sowerent Technology LLP Properties */}
                            {propertyNearBy.filter(
                              (nearBy) =>
                                nearBy.nearByTypes ===
                                exploreNearBy.NearestProperties
                            ).length > 0 && (
                              <div className="nearby-areas-properties-item">
                                <h3>
                                  <img src={residentialIcon} alt="" />
                                  Nearest SoweRent Properties
                                </h3>
                                <ul className="nearby-areas-properties-list">
                                  {propertyNearBy
                                    .filter(
                                      (nearBy) =>
                                        nearBy.nearByTypes ===
                                        exploreNearBy.NearestProperties
                                    )
                                    .map((item, index) => (
                                      <li key={`nearest-prop-${index}`}>
                                        <span className="location-name">
                                          <Link
                                            to={item.nearByLink}
                                            target="_blank"
                                          >
                                            {item.nearByName}
                                          </Link>
                                        </span>{" "}
                                        -{" "}
                                        <span className="location-distance">
                                          {item.distanceInKM} KM
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {propData?._Property?.mapUrl && (
                    <div className="explore-location" id="explore-location">
                      <div className="detail-card">
                        <h2>Location</h2>
                        <div className="map">
                          <iframe
                            src={propData?._Property?.mapUrl}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            className="contact-map"
                            title="Location for Property"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="right-col" id="book-now">
                  <div style={{ position: "sticky", top: "80px" }}>
                    <div className="book-schdule-card">
                      <div className="book-now-form">
                        <h2>Book Now</h2>
                        <div className="avail-for">
                          <span className="text">Available From -</span>
                          <span className="date">
                            {formatDate(propData?._Flat?.availableFrom)}
                          </span>
                        </div>
                        <Form.Group
                          className="form-group"
                          controlId="prop-date"
                        >
                          <Form.Label>
                            Moving Date{" "}
                            <span className="label-note">
                              (you can book before 10 Days)
                            </span>
                          </Form.Label>
                          <Calendar
                            name="reqFrom"
                            className="schedule-visit-calendar"
                            value={formData.reqFrom}
                            onChange={handleDateChange}
                            minDate={minDate}
                            maxDate={maxDate}
                            showIcon
                            placeholder="Select Date"
                            dateFormat="dd-mm-yy"
                          />
                          {errors.reqFrom && (
                            <span className="validation-error">
                              {errors.reqFrom}
                            </span>
                          )}
                        </Form.Group>

                        <Row>
                          <Col lg="6">
                            <Form.Group
                              className="form-group"
                              controlId="duration"
                            >
                              <Form.Label>Duration</Form.Label>
                              <Form.Select
                                className="select"
                                name="sayDuration"
                                value={formData.sayDuration}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Duration</option>
                                {durationOptions.map((duration) => (
                                  <option key={duration} value={duration}>
                                    {duration} Month{duration > 1 ? "s" : ""}
                                  </option>
                                ))}
                              </Form.Select>
                              {errors.sayDuration && (
                                <span className="validation-error">
                                  {errors.sayDuration}
                                </span>
                              )}
                            </Form.Group>
                          </Col>

                          <Col lg="6">
                            <Form.Group
                              className="form-group"
                              controlId="guests"
                            >
                              <Form.Label>No. of Guests</Form.Label>
                              <Form.Select
                                className="select"
                                name="guests"
                                value={formData.guests}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Guests</option>
                                {Array.from({ length: totalGuests }, (_, i) => (
                                  <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                  </option>
                                ))}
                              </Form.Select>
                              {errors.guests && (
                                <span className="validation-error">
                                  {errors.guests}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="get-updates">
                          <div className="terms-check">
                            <Form.Check
                              type="checkbox"
                              id="terms"
                              name="terms"
                              checked={formData.terms}
                              onChange={handleInputChange}
                              label={
                                <>
                                  I have read and agreed to the{" "}
                                  <span
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleShow}
                                  >
                                    terms and conditions
                                  </span>{" "}
                                  and hereby confirm to proceed.
                                </>
                              }
                            />
                            {errors.terms && (
                              <span className="validation-error">
                                {errors.terms}
                              </span>
                            )}
                          </div>

                          <div className="submit-btn-wrap">
                            <Button
                              className="submit-btn"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="or-content">
                      <span>OR</span>
                    </div>
                    <ScheduleMeeting
                      busy="New-String"
                      vitualVisit={propertyVideos}
                    />
                  </div>
                </div>
              </div>
              <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  {/* <TermsConditions /> */}
                  <Container>
                    <Row>
                      <Col lg={10} className="mx-auto">
                        <div className="section-heading">
                          <h1>Terms & Conditions</h1>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12}>
                        <TermsCondition />
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </Container>
            <Modal
              show={showLoginModal}
              onHide={() => setShowLoginModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <LoginForm onLoginSuccess={handleSubmit} LoginFrom="Popup" />
              </Modal.Body>
            </Modal>
          </section>
        </>
      )}
    </>
  );
};

export default PropertyDetail;
