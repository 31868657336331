import React from "react";
import Banner from "../components/home/banner";
import { HowItWork } from "../components/home/how-it-work";
import PropertyType from "../components/home/property-type";
import FeaturedProperties from "../components/home/featured-properties";
import CityListing from "../components/home/city-listing";
import HomeCounter from "../components/home/counter";
import DownloadApp from "../components/home/download-app";
import Testimonials from "../components/home/testimonials";
import FaqSection from "../components/home/faq-section";
import SocialMedia from "../components/home/social-media";
import HomeBlogs from "../components/home/home-blogs";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>
          Furnished and Semifurnished Rental Properties in Bangalore
        </title>
        <meta
          name="description"
          content="Explore properties, get insights, and discover your new home."
        />
      </Helmet>
      <div className="main-wrapper">
        <Banner />
        {/* <CityListing /> */}
        <HowItWork isBuildingSectionRequired={false} />
        <PropertyType />
        <FeaturedProperties />
        <HomeCounter />
        <DownloadApp />
        <Testimonials />
        <FaqSection />
        <HomeBlogs />
        <SocialMedia />
      </div>
      {/* <div className="progress-wrap active-progress">
        <svg className="progress-circle svg-content" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div> */}
    </>
  );
};

export default HomePage;
